<template>
  <div class="flex flex-col gap-8">
    <div
      class="flex flex-col w-full bg-white py-6 px-12 border border-gray-200 rounded-md"
    >
      <div class="text-lg">{{ subIndicator.name }}</div>
      <div class="grid grid-cols-5 gap-x-24">
        <div class="w-full col-span-3 my-2 bg-gray-200 rounded-full h-2.5">
          <div
            class="h-2.5 rounded-full"
            :style="progressBarStyle(subIndicator?.achieved_percentage)"
          ></div>
        </div>
        <div>{{ subIndicator.achieved_percentage }}%</div>
        <div>
          {{ subIndicator.achieved_points }} / {{ subIndicator.total_points }}
        </div>
      </div>
    </div>
    <div class="border border-gray-300 bg-white rounded-md">
      <SubIndicatorsTable
        :indicatorId="route.params.indicatorId"
        :indicatorName="subIndicator.name"
        :compare="true"
        :data="stats?.data"
        actionPlanPageRoute="hq-QOF-ActionPlan"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import SubIndicatorsTable from "@/components/newUi/tables/SubIndicatorsTable.vue";

const store = useStore();
const route = useRoute();

const subIndicatorId = route.params.subIndicatorId;
const stats = ref([]);
const subIndicator = ref({});

onMounted(async () => {
  subIndicator.value = await store.dispatch(
    "hqQof/fetchSingleSubIndicatorStats",
    subIndicatorId
  );
  stats.value = await store.dispatch(
    "hqQof/fetchSubIndicatorStats",
    subIndicatorId
  );
});

const progressBarStyle = (value) => {
  return {
    width: `${value}%`,
    "background-color":
      value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
  };
};
</script>
