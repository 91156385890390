<template>
  <div
    class="flex flex-row items-center py-1 lg:px-0 sm:px-6 px-6"
    :class="{
      'bg-teal bg-opacity-5': !compare,
    }"
  >
    <div class="justify-items-start p-6 text-lg w-full">
      <span v-if="compare">Sub Indicator Comparison</span>
      <p v-else>Sub-Indicators</p>
    </div>
    <div
      class="flex space-x-4 justify-end w-2/5 z-10"
      :class="indicatorDetailsPage ? 'pr-12' : 'pr-20'"
    >
      <v-select
        class="w-1/2 shadow-sm"
        label="Sort By"
        placeholder="Sort By"
        :options="sortingList"
        @update:modelValue="updateSorting"
      >
      </v-select>
    </div>
  </div>
  <table
    class="w-full text-sm text-left"
    :class="{
      'bg-teal bg-opacity-5': !compare,
    }"
  >
    <thead class="text-sm text-grey bg-transparent border-b">
      <tr>
        <th
          scope="col"
          class="p-6 tracking-wider"
          :class="{ 'bg-teal text-white': compare }"
          v-for="subHeader in subHeaders"
          :key="subHeader"
        >
          {{ subHeader }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="border-b border-gray-200"
        v-for="subIndicator in tableData"
        :key="subIndicator?.id"
      >
        <td class="px-6 py-8 w-1/3">
          <span>{{
            compare ? subIndicator?.practice_name : subIndicator?.name
          }}</span>
          <div
            v-if="!compare"
            class="w-full my-2 bg-gray-200 rounded-full h-2.5 mb-4"
          >
            <div
              class="h-2.5 rounded-full"
              :style="progressBarStyle(subIndicator?.achieved_percentage)"
            ></div>
          </div>
        </td>
        <td
          v-if="compare || detailPage"
          class="p-6 w-1/5 font-medium text-gray-900 overflow-hidden"
        >
          <div class="w-full my-2 bg-gray-200 rounded-full h-2.5 mb-4">
            <div
              class="h-2.5 rounded-full"
              :style="progressBarStyle(subIndicator?.achieved_percentage)"
            ></div>
          </div>
        </td>
        <td class="px-6 py-8">{{ subIndicator?.achieved_percentage }}%</td>
        <td class="px-6 py-8">
          {{ subIndicator?.achieved_points }} / {{ subIndicator?.total_points }}
        </td>
        <td class="px-6 py-8">
          {{
            compare
              ? subIndicator?.served_patients
              : subIndicator?.total_served_patients
          }}
          /
          {{ subIndicator?.total_patients }}
        </td>
        <td class="px-6 py-8">
          {{
            compare
              ? subIndicator?.missing_patients
              : subIndicator?.total_missing_patients
          }}
          / {{ subIndicator?.total_patients }}
        </td>
        <td class="px-6 py-8" v-if="!compare && role === 'hq'">
          <button
            class="py-2 px-5 border hover:text-white hover:border-transparent rounded"
            :style="actionButtonStyle(subIndicator?.achieved_percentage)"
            :class="
              subIndicator?.achieved_percentage >= 80
                ? 'hover:bg-teal'
                : subIndicator?.achieved_percentage >= 80
                ? 'hover:bg-orange'
                : 'hover:bg-red-500'
            "
            @click="
              router.push({
                name: 'hq-subIndicator-details',
                params: {
                  subIndicatorId: subIndicator?.id,
                  indicatorId: props.indicatorId,
                },
              })
            "
          >
            Compare
          </button>
        </td>
        <td
          v-if="detailPage || compare || role === 'manager'"
          class="px-6 py-8"
        >
          <button
            class="py-2 px-5 border hover:text-white hover:border-transparent rounded"
            :style="actionButtonStyle(subIndicator?.achieved_percentage)"
            :class="
              subIndicator?.achieved_percentage >= 80
                ? 'hover:bg-teal border-teal text-teal'
                : subIndicator?.achieved_percentage >= 50
                ? 'hover:bg-orange border-orange text-orange'
                : 'hover:bg-red-500 border-red-500 text-red-500'
            "
            @click="routeToActionPlan(subIndicator?.id)"
          >
            Take Action
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { defineProps, ref, toRefs, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const sortingList = ref(["Ascending", "Descending"]);
const role = localStorage.getItem("role");
const subHeaders = computed(() => {
  return props.compare || props.detailPage
    ? [
        "List",
        props.indicatorName,
        "% Age",
        "Points",
        "Patient Count",
        "Missing Patient",
        "Action",
      ]
    : ["List", "% Age", "Points", "Patient Count", "Missing Patient"];
});
const indicatorDetailsPage = computed(() =>
  route.path.includes("hq-indicator-details")
);

if (!props.compare && role === "hq") {
  subHeaders.value.push("Comparison");
}

if (role === "manager") {
  subHeaders.value.push("Action");
}

const props = defineProps({
  data: {
    type: Array,
  },
  detailPage: {
    type: Boolean,
    default: false,
  },
  compare: {
    type: Boolean,
    default: false,
  },
  actionPlanPageRoute: {
    type: String,
    required: true,
  },
  indicatorId: {
    type: Number,
    required: false,
  },
  indicatorName: {
    type: String,
    required: false,
  },
  showAction: {
    type: Boolean,
    required: false,
  },
});

const { data } = toRefs(props);
const tableData = ref(data);

const updateSorting = (value) => {
  tableData.value.sort((a, b) => {
    const aValue = a.achieved_percentage;
    const bValue = b.achieved_percentage;

    if (value === "Ascending") {
      return aValue - bValue;
    } else {
      return bValue - aValue;
    }
  });
};

const progressBarStyle = (value) => {
  return {
    width: `${value}%`,
    "background-color":
      value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
  };
};

const routeToActionPlan = (id) => {
  if (role === "hq") {
    router.push({
      name: props.actionPlanPageRoute,
      params: {
        indicatorId: props.compare ? route.params.indicatorId : route.params.id,
        subIndicatorId: id,
        qof: true,
        practiceId: props.indicatorId,
      },
    });
  } else if (role === "manager") {
    router.push({
      name: props.actionPlanPageRoute,
      params: { indicatorId: props.indicatorId, subIndicatorId: id, qof: true },
    });
  }
};

const actionButtonStyle = (value) => {
  return {
    "border-color":
      value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
    color: value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
    "background-color-color":
      value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
  };
};
</script>
